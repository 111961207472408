import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';
import { languagesHelper as language } from '../../helpers/languages';
import css from './ListingPage.module.css';
import { mapVariantListingFields } from '../../util/genericHelpers';

const SectionDetailsMaybe = props => {
  const { publicData, metadata = {}, listingConfig, intl, selectedVariant } = props;
  const { listingFields } = listingConfig || {};

  if (!publicData || !listingConfig) {
    return null;
  }

  const pickListingFields = (filteredConfigs, config) => {
    const { key, schemaType, enumOptions, showConfig = {} } = config;
    const { isDetail, label } = showConfig;
    const publicDataValue = publicData[key];
    const metadataValue = metadata[key];
    const value = publicDataValue || metadataValue;

    if (isDetail && typeof value !== 'undefined') {
      const findSelectedOption = enumValue => enumOptions?.find(o => enumValue === `${o.option}`);
      const getBooleanMessage = value =>
        value
          ? intl.formatMessage({ id: 'SearchPage.detailYes' })
          : intl.formatMessage({ id: 'SearchPage.detailNo' });
      const optionConfig = findSelectedOption(value);

      return schemaType === 'enum'
        ? filteredConfigs.concat({ key, value: optionConfig?.label, label })
        : schemaType === 'boolean'
        ? filteredConfigs.concat({ key, value: getBooleanMessage(value), label })
        : schemaType === 'long'
        ? filteredConfigs.concat({ key, value, label })
        : filteredConfigs;
    }
    return filteredConfigs;
  };

  const existingListingFields = listingFields.reduce(pickListingFields, []);
  const reformedListingFields = selectedVariant?.id
    ? mapVariantListingFields(existingListingFields, selectedVariant)
    : existingListingFields;

  return existingListingFields.length > 0 ? (
    <div className={css.sectionDetails}>
      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.detailsTitle" />
      </Heading>
      <ul className={css.details}>
        {reformedListingFields.map(detail => {
          let labelKey = detail.value;

          // replace spaces with underscores for type and condition key (e.g. Like New -> like_new)
          if (detail.key === 'type' || detail.key === 'condition')
            labelKey = detail?.value?.toLowerCase().replaceAll(' ', '_');

          // use the first word of the size for a key if it contains a / (e.g. 116 / 6 years -> 116)
          if (detail.key === 'size')
            labelKey = (detail?.value?.indexOf('/') !== -1
              ? detail?.value?.slice(0, detail?.value?.indexOf('/')).trim()
              : detail?.value
            )?.toLowerCase();

          return (
            <li key={detail.key} className={css.detailsRow}>
              <span className={css.detailLabel}>
                {language.labelsTranslator(detail.key, intl)}
                {/* {detail.label} */}
              </span>
              <span>
                {!!detail.value
                  ? language.labelsTranslator(labelKey, intl)?.startsWith('CustomLabel.')
                    ? detail.value
                    : language.labelsTranslator(labelKey, intl)
                  : detail.value}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default SectionDetailsMaybe;
